import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import { profileDetail } from "../../../Redux/Actions/auth";
import { NavLink } from "react-router-dom";
import Layout from "../../components/Layout";
const ProfileView = () => {
  let { id } = useParams();
  const HOST_NAME = process.env.REACT_APP_HOST_NAME;
  const profileView = useSelector((state) => state?.adminSlice?.profile);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(profileDetail(id));
  }, []);

  function calculateAge(birthday) {
    if (birthday != undefined) {
      let date = birthday;
      let d = date.split("-");
      let birthd = new Date(d[1] + "-" + d[0] + "-" + d[2]); //(year:number, month:number, date:number, ...)

      console.log(birthd);

      var birthdate = new Date(birthd);

      var cur = new Date();
      var diff = cur - birthdate;
      return Math.floor(diff / 31557600000);
    }
  }
  return (
    <Layout>
      {" "}
      <div id="layout-wrapper">
        {profileView && (
          <div className="main-content">
            <div className="page-content">
              <div className="container-fluid">
                <div className="container bg_box_shadow">
                  <div className="row">
                    <div className="col-sm-12 mx-auto">
                      <div className="back_btn">
                        <button class="btn">
                          <NavLink to="/admin/user-management">
                            <b>Back</b>
                          </NavLink>
                        </button>
                      </div>
                      <div className="profile-img-part">
                        <div className="profile-img-circle">
                          {profileView?.image ? (
                            <img
                              src={
                                HOST_NAME + profileView?.image[0]?.main_image
                              }
                              alt=""
                            />
                          ) : null}
                        </div>
                        <div className="user-name-part">
                          <div className="user-name-medium">
                            {profileView?.first_name}
                          </div>
                          <div className="age-text">
                            Age - {calculateAge(profileView?.birthday)}
                          </div>
                          <div className="location">
                            {profileView.state}({profileView.country})
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="aboutme">
                        <div className="heading-purple-text">About</div>
                        <div className="output-text-view">
                          {profileView?.about_me}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-md-12 col-12 border-right_">
                      <div className="heading-purple-text">
                        Additional information
                      </div>
                      <div className="row">
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Ethnicity
                            <div className="output-text-view">
                              {profileView?.ethnicity}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Language
                            <div className="output-text-view">
                              {profileView?.my_first_language +
                                " " +
                                profileView?.my_second_language}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Country
                            <div className="output-text-view">
                              {profileView?.country}
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Relocate?
                            <div className="output-text-view">
                              {profileView?.relocate}
                            </div>
                          </div>
                        </div> */}
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            My Height
                            <div className="output-text-view">
                              {profileView?.my_height}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Marital Status
                            <div className="output-text-view">
                              {profileView?.matrial_status}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Registration
                            <div className="output-text-view">
                              {profileView?.registration_reason}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Religiousness
                            <div className="output-text-view">
                              {profileView?.religiousness}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            My following
                            <div className="output-text-view">
                              {profileView?.my_following}
                            </div>
                          </div>
                        </div>
                        {profileView?.gender == "Female" ? null : (
                          <div className="col-md-12 col-12">
                            <div className="subheading-black-text box-shadow-c">
                              Hijab/Niqab?
                              <div className="output-text-view">
                                {profileView?.hijab_niqab}
                              </div>
                            </div>
                          </div>
                        )}
                        {profileView?.gender == "Male" ? null : (
                          <div className="col-md-12 col-12">
                            <div className="subheading-black-text box-shadow-c">
                              Beard?
                              <div className="output-text-view">
                                {profileView?.beard}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Halal?
                            <div className="output-text-view">
                              {profileView?.halal}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Do you perform Salaah
                            <div className="output-text-view">
                              {profileView?.salaah}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Job
                            <div className="output-text-view">
                              {profileView?.user_jobs?.map(function( object_value,i){
                               return <span>
                          {object_value?.jobs_id?.job_title}
                          <br />
                                </span>
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Salary
                            <div className="output-text-view">
                              {profileView?.salary}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            I am looking to Marry
                            <div className="output-text-view">
                              {profileView?.marray}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Do I have Children?
                            <div className="output-text-view">
                              {profileView?.children}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Accommodation?
                            <div className="output-text-view">
                              {profileView?.accommodation}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Education
                            <div className="output-text-view">
                              {profileView?.education}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-12">
                          <div className="subheading-black-text box-shadow-c">
                            Subject I Studied
                            <div className="output-text-view">
                              {profileView?.subject_i_studied}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-12 mt-4">
                      <div className="heading-purple-text">Interest</div>
                      <div className="row">
                        <div className="intrest-black-text">
                          <ul className="intrest-text">
                            {profileView?.intrest?.map(function (object_data) {
                              return <li>{object_data?.hobbies_id?.name}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="aboutme1">
                          <div className="heading-purple-text">Headline</div>
                          <div className="output-text-view">
                            {profileView?.headline}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="aboutme1">
                          <div className="heading-purple-text">
                            What I am looking for{" "}
                          </div>
                          <div className="output-text-view">
                            {profileView?.looking_for}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="heading-purple-text">Gallery</div>
                        <ul className="gallery-img-thumb overscroll-y">
                          {profileView?.image?.map((item, i) => {
                            return (
                              <li>
                                <img
                                  src={HOST_NAME + item?.multi_image}
                                  alt=""
                                />
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="col-md-12 mt-4">
                        <div className="heading-purple-text">Selfie</div>

                        {/* <ul className="gallery-img-thumb overscroll-y">
                          {profileView?.image?.map((item, i) => {
                            return (
                              <li>
                                <img
                                  src={HOST_NAME + item?.multi_image}
                                  alt=""
                                />
                              </li>
                            );
                          })}
                        </ul> */}
                        <img
                          className="img-size-fix"
                          src={HOST_NAME + profileView?.selfie_pic}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ProfileView;
